/* Evom Global Styles */

.evom-example {
    color: #2B65EC;
    margin: 20px;
    margin-right: 10px;
    border: 2px solid #000;
}

.mensagem-erro{
    color:red;
}

.nowrap-content {
    white-space: nowrap;
}
